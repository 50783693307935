import React from 'react';
import { graphql } from 'gatsby';

import { PostTemplate } from 'components/templates';
import { MarkdownPageData } from 'types';

interface Props {
    data: MarkdownPageData;
}

export default function Post({ data }: Props) {
    const { frontmatter, html } = data.markdownRemark;

    return (
        <PostTemplate
            title={frontmatter.title}
            date={frontmatter.date}
            author={frontmatter.author}
            featuredImage={frontmatter.featuredImage}
            featuredImageAlt={frontmatter.featuredImage_alt}
            metaImage={frontmatter.metaImage}
            text={html}
            slug={frontmatter.slug}
            category={frontmatter.category}
            timeToRead={frontmatter.timeToRead}
            imageSource={frontmatter.imageSource}
            metaDescription={frontmatter.metaDescription}
        />
    );
}

export const pageQuery = graphql`
    query PostBySlug($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                featuredImage {
                    childImageSharp {
                        fluid(quality: 85, maxWidth: 3840) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                imageSource
                featuredImage_alt
                metaImage {
                    childImageSharp {
                        fluid(quality: 85, maxWidth: 3840) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                date
                author
                category
                timeToRead
                slug
                metaDescription
            }
        }
    }
`;
