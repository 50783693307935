import React from 'react';
import { formatDistanceToNow, parse } from 'date-fns';

import general from 'data/general.json';
import analytics from 'data/analytics.json';
import seo from 'data/seo.json';
import blog from 'data/blog.json';

import { BLOG_POST_DATE_FORMAT } from 'consts';
import { ImageSharpData } from 'types';
import { useMediaQuery, useQueryContext } from 'hooks';
import { MediaQueries } from 'environment';
import { TertiaryButton, SEO } from 'components/ui';
import {
    BlogPostHeader,
    BlogPostHero,
    BlogPostPreviewList,
    RecentArticles,
    BlogComments
} from 'components/blog';

import {
    Container,
    LeftColumn,
    MarkdownContent,
    PostContent,
    Content,
    ButtonContainer
} from './PostTemplate.style';

interface Props {
    title: string;
    date: string;
    author: string;
    slug: string;
    imageSource?: string;
    featuredImage?: ImageSharpData;
    featuredImageAlt?: string;
    metaImage?: ImageSharpData;
    metaDescription?: string;
    netlifyFeaturedImage?: string;
    text: string;
    category: string;
    timeToRead?: string;
    isNetlifyWidget?: boolean;
}

export function PostTemplate({
    title,
    date,
    author,
    slug,
    featuredImage,
    featuredImageAlt,
    metaImage,
    metaDescription,
    imageSource,
    netlifyFeaturedImage,
    text,
    category,
    timeToRead = '',
    isNetlifyWidget
}: Props) {
    const { posts } = useQueryContext();
    const isPhone = useMediaQuery(MediaQueries.phone);
    const isTablet = useMediaQuery(MediaQueries.tablet);
    const isDesktop = useMediaQuery(MediaQueries.desktop);

    const formattedDate = formatDistanceToNow(parse(date, BLOG_POST_DATE_FORMAT, new Date()), {
        addSuffix: true
    });
    const numberOfPreviewItemsAtTheBottom = isPhone ? 1 : isTablet ? 2 : 3;
    const ogImage = metaImage ? metaImage.childImageSharp.fluid.src : seo.blogPostOgImageAlt;

    const seoDescription = metaDescription ?? general.description;

    return (
        <Container>
            {!isNetlifyWidget && (
                <SEO
                    title={title}
                    description={seoDescription}
                    ogTitle={title}
                    ogDescription={seoDescription}
                    ogImage={ogImage}
                />
            )}
            <Content>
                <BlogPostHero
                    featuredImageAlt={featuredImageAlt}
                    featuredImage={featuredImage}
                    netlifyImage={netlifyFeaturedImage}
                    imageSource={imageSource}
                    isNetlifyWidget={isNetlifyWidget}
                />
                <PostContent>
                    <LeftColumn>
                        <BlogPostHeader
                            title={title}
                            date={formattedDate}
                            author={author}
                            category={category}
                            isNetlifyWidget={isNetlifyWidget}
                            timeToRead={timeToRead}
                        />
                        {isNetlifyWidget ? (
                            <MarkdownContent>{text}</MarkdownContent>
                        ) : (
                            <MarkdownContent dangerouslySetInnerHTML={{ __html: text }} />
                        )}
                    </LeftColumn>
                    {isDesktop && !isNetlifyWidget && (
                        <RecentArticles
                            posts={posts.filter((e) => e.title !== title).slice(0, 3)}
                        />
                    )}
                </PostContent>
                {!isNetlifyWidget && (
                    <>
                        <BlogPostPreviewList
                            posts={posts
                                .filter((e) => e.title !== title && e.category === category)
                                .slice(0, numberOfPreviewItemsAtTheBottom)}
                            title={category}
                            wrap={numberOfPreviewItemsAtTheBottom}
                        />
                        <ButtonContainer>
                            <TertiaryButton
                                name={analytics.blogPostViewAllArticles}
                                to="/blog/"
                                width={isPhone ? '100%' : isTablet ? 300 : 340}
                                textTransform="none"
                            >
                                {blog.viewAll}
                            </TertiaryButton>
                        </ButtonContainer>
                        <BlogComments title={title} id={slug} />
                    </>
                )}
            </Content>
        </Container>
    );
}
