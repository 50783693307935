import styled from 'styled-components';
import { MediaQueries, Colors } from 'environment';
import {
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING,
    TABLET_GRID_SIZE,
    DESKTOP_GRID_SIZE
} from 'consts';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${Colors.violet};
    margin-bottom: 12rem;

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
        margin-bottom: 6rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }
`;

export const PostContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 8rem;

    @media ${MediaQueries.phone} {
        padding-top: 4rem;
    }
`;

export const LeftColumn = styled.div`
    max-width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 7 + DESKTOP_GRID_SPACING * 6}rem;
    }
`;

export const MarkdownContent = styled.div`
    padding-top: 8rem;

    h1 {
        // TODO: we need to update headers font size to be consistent!
        font-size: 3.6rem;
        line-height: 5rem;
        margin-top: 2rem;
        margin-bottom: 3rem;

        &:first-child {
            margin-top: 0;
        }
    }

    h2 {
        font-size: 2.4rem;
        line-height: 3.6rem;
        text-transform: none;
        margin-top: 2rem;
        margin-bottom: 3rem;

        * {
            font-size: 2.4rem;
            line-height: 3.6rem;
        }
    }

    h3,
    h4 {
        font-size: 2rem;
        margin-top: 3.2rem;
        margin-bottom: 4rem;
        line-height: 3.8rem;

        @media ${MediaQueries.phone} {
            margin-bottom: 2rem;
            margin-top: 0;
        }
    }

    img {
        width: 100%;
        margin-top: 5.3rem;

        @media ${MediaQueries.phone} {
            margin-top: 1.4rem;
        }
    }

    p {
        margin-bottom: 2.8rem;
    }

    code[class*='language-'],
    code * {
        font-size: 1.8rem;
        background: transparent !important;
        color: #abb2bf;
        text-shadow: none;
    }

    code:not([class*='language-']) {
        padding: 0.2em;
        margin: 0;
        font-size: 85%;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 3px;
    }

    //for preview purpose try here to mimic styles injected by https://prismjs.com
    pre {
        background-color: rgb(40, 44, 52);
        padding: 1em;
        overflow: auto;
        margin: 1em 0 2em;
        border-radius: 0.5em;
        border: 0.3em solid hsl(0, 0%, 33%);
        box-shadow: 1px 1px 0.5em black inset;
    }

    ul {
        list-style: none;
        margin-bottom: 2rem;
    }

    ol {
        margin-bottom: 2rem;
        margin-left: 2rem;

        li {
            padding-left: 1rem;
        }
    }

    blockquote {
        padding: 0 1em;
        color: #777;
        border-left: 0.5em solid #ddd;
    }

    a {
        color: ${Colors.green};
    }

    @media ${MediaQueries.phone} {
        padding-top: 4rem;
    }
`;

export const ButtonContainer = styled.div`
    max-width: 34rem;
    width: 100%;

    @media ${MediaQueries.phone} {
        max-width: 100%;
    }
`;
